.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.hint {
  margin-top: 16px;
}

.card {
  margin: 24px;
}

.icon {
  margin: 4px;
}
